<template>
  <div class="p-l-md p-r-md">
    <ListFrame
      :immediateInit="false"
      :viewConf="{showReorder:false,showTitle:true,showReset:true}"
      :getPageFn="getPage"
      :customedParams="{contentType:laun+'Teaching'}"
    >
      <template slot-scope="{item}">
        <TeachingItem :info="item" />
        <Button type="warning" @click="preview(item)">查看</Button>
      </template>
      <template slot="conditions" slot-scope="{requestFn}">
        <TeachingForm v-if="$store.getters.getRoleCode===0" @success="requestFn" />
      </template>
    </ListFrame>
    
  </div>
</template>

<script>
import TeachingForm from './TeachingForm';
import { getList } from './services/teachingService';
import { getSubmitedInfo } from './services/api';
export default {
  components: { TeachingForm },
  data() {
    return {

    };
  },
  computed:{
    laun(){
      return this.$store.getters.getLaun
    }
  },
  methods: {
    getPage(params) {
      return getList('history', params);
    },
    preview(item) {
      getSubmitedInfo({
        bizId: item.bizId,
        taskName: this.$store.getters.getRoleCode,
        contentType: item.contentType
      }).then((res) => {        
        this.$mobilePreview(res.content)
      });
    }
  }
};
</script>

<style scoped>

</style>